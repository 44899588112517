import AllModals from "../components/modals/_AllModals";
import Header from "../components/header/Header";

export default function WelcomePage(props: {
    handleViewHomepage: () => void,
}) {
    return <>
        <Header handleViewHomepage={props.handleViewHomepage} isWelcomePage={true} displayViewHomepageButton={true} displayHero={true}/>
        <AllModals/>
    </>
}
